<script>
import ax from '../../helpers/restful/service';
import {
    teacherMainLayoutMethods,
    teacherMainLayoutComputed,
} from "@/state/helpers";
import Horizontal from "./horizontal-no-user.vue";

/**
 * Main Layout
 */
export default {
    components: {
        Horizontal,
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'row_id',
                    label: 'ลำดับ',
                    sortable: true,
                    variant: 'success'
                },    
                {
                    key: 'identity_number',
                    label: 'เลขประจำตัวประชาชน',
                    sortable: true,
                },    
                {
                    key: 'firstname',
                    label: 'ชื่อ',
                    sortable: true,
                },    
                {
                    key: 'lastname',
                    label: 'นามสกุล',
                    sortable: true,
                },    
                {
                    key: 'phone',
                    label: 'เบอร์โทร',
                    sortable: true,
                },    
                {
                    key: 'payment_dt',
                    label: 'วัน/เวลาชำระ',
                    sortable: true,
                },    
                {
                    key: 'amount',
                    label: 'จำนวนชำระ',
                },    
                {
                    key: 'test_result.score',
                    label: 'คะแนนสอบ',
                    sortable: true,
                },    
                {
                    key: 'test_result.way_to_get_document',
                    label: 'เลือกรับเอกสาร',
                    sortable: true,
                },    
                {
                    key: 'test_result.test_payment_dt',
                    label: 'วัน/เวลาชำระค่าส่งเอกสาร',
                    sortable: true,
                },    
            ],
            isBusy: true,
        };
    },
    computed: {
        ...teacherMainLayoutComputed,
    },
    mounted() {
        document.body.classList.add('authentication-bg');
        this.loadData();
    },
    methods: {
        ...teacherMainLayoutMethods,
        async loadData() {
            const resp = await ax.get("/admin-get-registed-list");
            console.log("resp:", resp);
            if (resp.data.length > 0) {
                this.items = resp.data;
            }
            this.isBusy = false;
        }
    },
};
</script>

<template>
    <div>
        <Horizontal :layout="layoutType">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <h4>รายชื่อผู้สมัครครูแพทย์แผนไทย</h4>
                        </div>
                    </div>
                </div>
                <b-table bordered hover :items="items" :fields="fields" :busy="isBusy" class="text-center">
                    <template #cell(row_id)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(payment_dt)="data">
                        {{ data.item.payment_dt == null ? "ยังไม่ชำระ" : new Date(data.item.payment_dt) }}
                    </template>
                    <template #cell(amount)="data">
                        {{ data.item.amount }}
                    </template>
                    <template #cell(test_result.score)="data">
                        {{ data.item.test_result == null ? "ยังไม่ได้สอบ" : data.item.test_result.score }}
                    </template>
                    <template #cell(test_result.way_to_get_document)="data">
                        {{ data.item.test_result == null ? "ไม่พบข้อมูล" : (data.item.test_result.way_to_get_document == 'thaipost' ? "รับทางไปรษณีย์ จัดส่งที่ ": "รับด้วยตนเอง") }}
                        <p>{{ data.item.test_result == null ? "" : (data.item.test_result.way_to_get_document == 'thaipost' ? data.item.address : "") }}</p>
                    </template>
                    <template #cell(test_result.test_payment_dt)="data">
                        {{ data.item.test_result == null ? "ไม่พบข้อมูล" : ( data.item.test_result.way_to_get_document == "self" ? "-" : (data.item.test_result.test_payment_dt == null ? "ยังไม่ชำระค่าจัดส่ง 100 บาท" : new Date(data.item.test_result.test_payment_dt))) }}
                    </template>
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>โหลดข้อมูล...</strong>
                        </div>
                    </template>
                </b-table>
            </div>
        </Horizontal>
    </div>
</template>
