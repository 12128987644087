<script>
import Layout from "../../layouts/teacher-registed-list.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


export default {
  page: {
    title: "รายชื่อผู้สมัครครูแพทย์แผนไทย",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "ระบบหลัก",
      items: [
        {
          text: "Thaimed",
        },
        {
          text: "รายชื่อผู้สมัครครูแพทย์แผนไทย",
          active: true,
        },
      ],
      currentUser: {},
      attach: this.$route.params.attach,
    };
  },
  methods: {
    
  },
};
</script>


<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
  </Layout>
</template>